// import { formatDate } from '~/lib/formatDate';
import type { ArticleWithSlug } from '~/lib/articles';
import { Card } from '~/components/Card';
import Markdown from 'markdown-to-jsx';

export function ArticleCard({
  article,
  children,
}: {
  article: ArticleWithSlug;
  children?: React.ReactNode;
}) {
  return (
    <Card as="article">
      <div className="absolute -inset-x-4 -inset-y-6 z-0 scale-95 bg-zinc-50 opacity-0 transition group-hover:scale-100 group-hover:opacity-100 sm:-inset-x-6 sm:rounded-2xl dark:bg-zinc-800/50" />
      {article.heroImage ? (
        <div className="lg:aspect-square relative aspect-[16/9] sm:aspect-[2/1] lg:w-64 lg:shrink-0">
          <img
            alt=""
            src={article.heroImage}
            className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
          />
          <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
        </div>
      ) : null}
      <div className="group relative max-w-xl">
        <Card.Title href={`/articles/${article.slug}`}>
          {article.title}
        </Card.Title>
        {/* <Card.Eyebrow as="time" dateTime={article.date} decorate>
        {formatDate(article.date)}
      </Card.Eyebrow> */}
        <Card.Description>
          <Markdown>{article.description}</Markdown>
        </Card.Description>
        {children}
        {/* <Card.Cta>Read article</Card.Cta> */}
      </div>
    </Card>
  );
}
