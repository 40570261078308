import type * as schema from '~/db/schema.server';
import type { ArticleWithSlug } from './articles';

type Page = typeof schema.PageEntity.$inferSelect;

type Author = typeof schema.AuthorEntity.$inferSelect;

type Media = typeof schema.MediaEntity.$inferSelect;

// Create serialized versions of the base types
type SerializedPage = Omit<Page, 'date'> & {
  date: string | null;
};

type SerializedArticleInput = SerializedPage & {
  authors: {
    author: Author;
  }[];
  media: {
    media: Media;
  }[];
};

export function transformArticle(
  article: SerializedArticleInput,
): ArticleWithSlug {
  return {
    ...article,
    authors: article.authors.flatMap(({ author }) =>
      author ? [{ ...author }] : [],
    ),
    date: article.date ? new Date(article.date) : null,
    heroImage: article.media[0]?.media?.url,
    meta:
      article.meta ??
      {
        /* default ArticleMeta values */
      },
  };
}
